﻿.more {
    text-align: center;
    margin-top: 30px;
    transition: transform 0.5s linear;

    a {
        text-decoration: none;
        color: #71a8bc;
        font-size: 18px;
        font-weight: 500;

        img {
            transition: transform 0.2s linear;
        }
    }

    &:hover {
        a {
            text-decoration: none;

            img {
                transform: translateX(15px);
            }
        }
    }
}

.title-partner {
    margin-bottom: 20px;
}

.title-partner, .icon-partner {
    margin-top: 100px;
    text-align: center;

    h2 {
        font-size: 45px;
        font-weight: 500;
        text-align: center;
        color: #225979;
        margin: 0;
        padding: 0;
    }
}

.title-icon {
    h2 {
        color: #225979;
        font-size: 40px;
        text-align: center;
    }
}

.sub-title {
    color: #808380;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
}

.search-engine {
    position: relative;


    .big-title {
        position: relative;
        margin-top: 50px;
        height: 49px;

        h1 {
            font-size: 35px;
            font-weight: 500;
            color: #225979;
            text-align: center;
            margin: 0;
            padding: 0;
        }
    }

    .search-types {
        margin: 30px 5px;
        position: relative;

        .nav-tabs > li > a {
            border: 0;
        }

        .nav {
            margin: 0;
            padding: 0;
            display: inline-block;
            border-radius: 3px;
            box-shadow: 0 0 4px 0 rgba(21,99,140,0.33);

            > li {
                margin-bottom: 0px;
                border-right: 1px solid #e4f6ff;
                background: #fff;

                &:last-child {
                    border: none;
                }

                > a {
                    padding: 35px 20px 5px;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    color: #7cb4d2;
                    margin-right: 0px;
                    border-right: 0;
                    background-color: transparent !important;
                    z-index: 100;


                    &:active, &:focus, &:hover {
                        text-decoration: none;
                        /*background-color: #e4f6ff !important;*/
                        outline: 0;
                        box-shadow: 0px;
                    }
                }

                &:before {
                    display: inline-block;
                    content: "";
                    position: absolute;
                    top: 11px;
                    width: 100%;
                    height: 22px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    z-index: 8;
                }

                &.icon-flight::before {
                    background-image: url(../images/search-menu/ucak-mavi.svg);
                }

                &.icon-bus::before {
                    background-image: url(../images/search-menu/otobus-mavi.svg);
                }

                &.icon-hotel::before {
                    background-image: url(../images/search-menu/otel-mavi.svg);
                }

                &.icon-ferry::before {
                    background-image: url(../images/search-menu/feribot-mavi.svg);
                }

                &.active {
                    > a {
                        color: #fff;
                        background-color: #7cb4d2 !important;
                        border: 0px;
                        border-radius: 0;
                        box-shadow: 0 0 4px 0 rgba(21,99,140,0.33);
                        z-index: 0;

                        &:hover, &:focus {
                            color: #fff;
                            background-color: #7cb4d2 !important;
                        }

                        &:before {
                            width: 0;
                            height: 0;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-top: 10px solid #7cb4d2;
                            position: absolute;
                            bottom: -10px;
                            left: 50%;
                            margin-left: -8px;
                            content: "";
                            display: inline-block;
                        }
                    }

                    &.icon-flight::before {
                        background-image: url(../images/search-menu/ucak-beyaz.svg);
                    }

                    &.icon-bus::before {
                        background-image: url(../images/search-menu/otobus-beyaz.svg);
                    }

                    &.icon-hotel::before {
                        background-image: url(../images/search-menu/otel-beyaz.svg);
                    }

                    &.icon-ferry::before {
                        background-image: url(../images/search-menu/feribot-beyaz.svg);
                    }
                }
            }
        }

        .onoffswitch {
            width: 125px;
            position: absolute;
            right: 94px;
            top: 75px;
        }

        .onoffswitch-checkbox {
            display: none;

            &:checked {
                + {
                    .onoffswitch-label {
                        .onoffswitch-inner {
                            margin-left: 0;
                        }

                        .onoffswitch-switch {
                            right: 0px;
                        }
                    }
                }
            }
        }

        .onoffswitch-label {
            display: block;
            overflow: hidden;
            cursor: pointer;
            border-radius: 5px;
            -webkit-box-shadow: 0 0 4px 0 rgba(21,99,140,0.33);
            box-shadow: 0 0 4px 0 rgba(21,99,140,0)
        }

        .onoffswitch-inner {
            display: block;
            width: 200%;
            margin-left: -100%;

            &:before {
                display: block;
                float: left;
                width: 50%;
                height: 25px;
                padding: 0;
                line-height: 25px;
                font-size: 12px;
                color: white;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                content: "Tek Yön";
                padding-left: 10px;
                background-color: #FFFFFF;
                color: #71A8BC;
                font-weight: 500;
                -webkit-border-radius: 20px;
                border-radius: 10px;
            }

            &:after {
                display: block;
                float: left;
                width: 50%;
                height: 25px;
                padding: 0;
                font-size: 12px;
                color: white;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                content: "Gidiş Dönüş";
                padding-right: 10px;
                background-color: #FFFFFF;
                color: #71A8BC;
                text-align: right;
                line-height: 25px;
                font-weight: 500;
                border-radius: 10px;
            }
        }

        .onoffswitch-switch {
            display: block;
            width: 35px;
            height: 19px;
            margin: 3px;
            background: #71A8BC;
            position: absolute;
            top: 0;
            bottom: 0;
            border: 2px solid #71A8BC;
            -webkit-border-radius: 100%;
            border-radius: 10px;
            margin-right: 4px;
        }
    }
}

.campaign {
    position: relative;

    .title-campaign {
        h2 {
            color: #808380;
        }
    }

    .boxes {
        margin-top: 35px;

        .box {
            position: relative;

            .img {
                z-index: 1;
                border-radius: 15px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 15px;
                    transition: transform .2s;
                }

                &:hover {
                    box-shadow: 0 0 70px rgba(21, 99, 140, 0.33);

                    img {
                        transform: scale(1.1);
                    }
                }
            }

            .content {
                position: absolute;
                padding: 20px;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .time {
                    font-size: 14px;
                    font-weight: bold;
                    color: #fff;

                    img {
                        width: 24px;
                        height: 24px;
                    }
                }

                .city {
                    position: absolute;
                    font-size: 24px;
                    color: #ffffff;
                    bottom: 0;
                    bottom: 10px;

                    span {
                        font-weight: bold;
                    }
                }

                .examine {
                    font-size: 14px;
                    color: #fff;
                    font-weight: bold;
                    position: absolute;
                    right: 23px;
                    bottom: 13px;
                    outline: 0;
                    text-decoration: none;

                    img {
                        width: 20px;
                        height: 20px;
                        margin: 0 1px;
                        transition: transform 0.2s linear;
                    }
                }
            }

            &:hover {
                .examine {
                    img {
                        transform: translateX(15px);
                    }
                }
            }
        }
    }
}

.why-choose {
    position: relative;


    .boxes {
        position: relative;
        margin-top: 65px;

        .box {
            .icon {
                text-align: center;
                width: 100px;
                height: 100px;
                -webkit-border-radius: 100%;
                border-radius: 100%;
                position: relative;
                margin: auto;
                background: #71a8bc;
                -webkit-box-shadow: 0px 0px 0px 6px #fff;
                box-shadow: 0px 0px 0px 6px #fff;

                img {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 64px;
                }
            }

            .title {
                margin-top: 20px;
                color: #808380;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                margin-bottom: 10px;
            }

            .text {
                color: #808380;
                font-size: 14px;
                font-weight: 400;
                text-align: justify;
            }
        }
    }
}

.cheap-flights {
    position: relative;

    .title-center {
        margin-top: 30px;
        font-size: 24px;
        font-weight: 500;
        color: #808380;
    }

    .domestic-title {
        text-align: center;
    }

    .abroad-title {
        text-align: center;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;

        li {
            border-radius: 5px;
            box-shadow: 0 0 4px 0 rgba(21, 99, 140, 0.33);
            margin-top: 20px;
            position: relative;

            &.left {

                &:before {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    border-radius: 16px;
                    content: '';
                    display: inline-block;
                    background: #71a8bc;
                    z-index: 2;
                    left: -40px;
                    top: 23px;
                }

                &:after {
                    position: absolute;
                    width: 1px;
                    height: 75px;
                    content: '';
                    display: inline-block;
                    border-right: 1px dashed #71a8bc;
                    left: -32px;
                    top: 30px;
                }
            }

            &.right {

                &:before {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    border-radius: 16px;
                    content: '';
                    display: inline-block;
                    background: #71a8bc;
                    z-index: 2;
                    right: -40px;
                    top: 23px;
                }

                &:after {
                    position: absolute;
                    width: 1px;
                    height: 75px;
                    content: '';
                    display: inline-block;
                    border-right: 1px dashed #71a8bc;
                    right: -32px;
                    top: 30px;
                }
            }
        }
    }

    ul:last-child > li:after {
        display: none;
    }

    .box {
        padding: 10px 0px;

        .price {
            font-size: 25px;
            font-weight: 500;
            text-align: center;
            color: #71a8bc;
        }

        .departure {
            font-size: 18px;
            font-weight: 500;
            text-align: right;
            margin-top: 5px;
            color: #808380;
        }

        .line {
            width: 100%;
            height: 1px;
            background-color: #71a8bc;
            position: relative;
            top: 18px;

            &:after {
                display: inline-block;
                width: 19px;
                height: 18px;
                right: 0;
                top: -8px;
                content: "";
                position: absolute;
                background: url(../images/icons/ucak.svg) no-repeat;
            }
        }

        .arrival {
            font-size: 18px;
            font-weight: 500;
            margin-top: 5px;
            color: #808380;
        }

        .brand {
            font-size: 12px;
            font-weight: 500;
            color: #808380;

            img {
                max-height: 40px !important;
            }
        }
    }
}

.abroad-flights {
    position: relative;

    .boxes {
        .box {
            margin-top: 40px;
            position: relative;
            overflow: hidden;
            border-radius: 15px;
            box-shadow: 0 0 4px 0 rgba(21,99,140,0.33);

            &:hover {
                box-shadow: 0 0 70px rgba(21, 99, 140, 0.33);
            }

            a {
                text-decoration: none;
            }

            .img {
                overflow: hidden;
                width: 100%;
                -webkit-border-top-right-radius: 15px;
                -moz-border-radius-topright: 15px;
                border-top-right-radius: 15px;

                img {
                    transition: transform .2s
                }

                &:hover {
                    box-shadow: 0 0 70px rgba(21, 99, 140, 0.33);

                    img {
                        transform: scale(1.1);
                    }
                }
            }

            .content {
                padding: 15px 22px;
                background-color: #ffffff;
                position: relative;

                .city {
                    font-size: 18px;
                    font-weight: 500;
                    color: #71a8bc;
                }

                .explore {
                    font-size: 14px;
                    font-weight: 500;
                    color: #71a8bc;
                    position: absolute;
                    text-align: right;
                    right: 40px;
                    bottom: 15px;

                    img {
                        margin-left: 6px;
                        transition: transform 0.2s linear;
                    }
                }
            }

            &:hover {
                .explore {
                    img {
                        transform: translateX(15px);
                    }
                }
            }
        }
    }
}

.comment {
    position: relative;

    .comment-images {
        position: relative;
        margin-top: 50px;

        img {
            width: 100%;
            height: 100%;
        }

        &:before {
            background: url(../images/comment/alinti.svg) no-repeat;
            background-size: contain;
            content: "";
            position: absolute;
            width: 107px;
            height: 76px;
            bottom: -25px;
            left: -57px;
        }
    }

    .comment-text {
        margin-top: 25px;
        position: relative;

        p {
            font-size: 25px;
            font-weight: 500;
            text-align: right;
            color: #808380;
        }
    }
}

.popular {
    margin-top: 100px;

    .title {
        font-size: 18px;
        font-weight: bold;
        color: #71a8bc;
        text-align: center;
    }

    .domestic-list {
        margin-top: 23px;
        position: relative;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                border-radius: 5px;
                box-shadow: 0 0 4px 0 rgba(21, 99, 140, 0.33);
                margin-top: 20px;

                &.bottom {
                    position: relative;

                    &:before {
                        position: absolute;
                        width: 1px;
                        height: 19px;
                        content: '';
                        display: inline-block;
                        border-right: 1px dashed #979797;
                        left: 50%;
                        top: 64px;
                    }

                    .img {
                       border-radius:5px;
                       overflow:hidden;
                    }
                }

                .city {
                    display: flex;
                    height: 63px;
                    /*text-align: center;*/
                    .text {
                        font-size: 16px;
                        font-weight: 500;
                        color: #808380;
                        display: block;
                        margin: auto;
                        float: left;
                        width: 100%;
                    }
                }
            }

            li:last-child:before {
                display: none;
            }
        }
    }

    .brand-list {
        margin-top: 23px;
        position: relative;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                border-radius: 5px;
                box-shadow: 0 0 4px 0 rgba(21, 99, 140, 0.33);
                margin-top: 20px;
                height: 64px;

                &.bottom {
                    position: relative;

                    &:before {
                        position: absolute;
                        width: 1px;
                        height: 19px;
                        content: '';
                        display: inline-block;
                        border-right: 1px dashed #979797;
                        left: 50%;
                        top: 65px;
                    }
                }

                .city {
                    text-align: center;
                    margin-top: 13px;
                }

                .text {
                    display: inline-block;
                    font-size: 18px;
                    font-weight: 500;
                    color: #808380;
                    margin-top: 20px;
                }
            }

            li:last-child:before {
                display: none;
            }
        }
    }
}

.business-partners {
    margin-bottom: 50px;

    .title {
        margin: 35px 0;
    }

    .img {
        text-align: center;
    }
}

@media screen and (max-width: 1200px) {
    .title-partner, .icon-partner {
        margin-top: 50px;

        h2 {
            font-size: 34px;
        }
    }

    .title-icon {
        h2 {
            font-size: 34px;
        }
    }

    .cheap-flights {
        ul {
            li {
                &.left {
                    &:before {
                        left: -22px;
                    }

                    &:after {
                        left: -15px;
                    }
                }

                &.right {
                    &:before {
                        right: -22px;
                    }

                    &:after {
                        right: -15px;
                    }
                }
            }
        }
    }

    .comment {
        .comment-images {
            &:before {
                width: 85px;
                height: 60px;
                left: -37px;
            }
        }
    }

    .popular {
        margin-top: 50px;
    }
}

@media screen and (max-width: 1024px) {
    .search-engine {
        .big-title {
            margin-top: 20px;
            margin-bottom: 10px;
            height: auto;
            padding-bottom: 10px;

            h1 {
                font-size: 38px;
            }
        }
    }

    .why-choose {
        .boxes {
            margin-top: 10px;

            .box {
                margin-bottom: 30px;
                float: left;

                .text {
                    font-size: 10px;
                }

                .col-sm-3 {
                    width: 100%;
                }

                &:after {
                    content: "";
                    clear: both;
                    display: block;
                }
            }

            .item:nth-child(3n+3) {
                clear: both;
            }
        }
    }

    .cheap-flights {
        .box {
            .departure {
                font-size: 14px;
            }

            .arrival {
                font-size: 14px;
            }
        }
    }

    .popular {
        .brand-list {
            ul {
                li {
                    .text {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {

    #martkampanya {
        display: block !important;
    }

    .campaign .boxes {
        margin-top: 0 !important;
        margin-bottom: 35px !important;
    }

    .title-partner, .icon-partner {
        h2 {
            font-size: 30px;
        }
    }

    .title-icon {
        h2 {
            font-size: 30px;
        }
    }

    .sub-title {
        font-size: 20px;
    }

    .campaign {
        .boxes {
            .box {
                .content {
                    padding: 12px;

                    .city {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .why-choose {
        .boxes {
            .box {
                margin-bottom: 10px;

                .title {
                    font-size: 14px;
                    margin-top: 10px;
                }

                .text {
                    text-align: center;
                    height: 110px;
                }
            }
        }
    }

    .cheap-flights {
        .abroad-title {
            text-align: left;
        }

        ul {
            li {
                margin-top: 6px;

                &.right {
                    &:before {
                        left: -22px;
                    }

                    &:after {
                        left: -15px;
                    }
                }
            }
        }
    }

    .abroad-flights {
        .boxes {
            .box {
                .img {
                    height: 124px;

                    img {
                        width: 100%;
                    }
                }

                .content {
                    .city {
                        font-size: 16px;
                    }

                    .explore {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .comment {
        .comment-text {
            p {
                font-size: 16px;
            }
        }
    }

    .popular {
        .domestic-list {
            ul {
                li {
                    &.bottom {

                        &:before {
                            top: 48px;
                        }

                        .img {
                            img {
                                width: 100%;
                            }
                        }
                    }

                    .city {
                        .text {
                            font-size: 14px;
                            margin-top: 14px;
                        }
                    }
                }
            }
        }

        .brand-list {
            text-align: center;

            ul {
                li {
                    height: 46px;

                    &.bottom {
                        &:before {
                            top: 48px;
                        }

                        .city {
                            margin-top: 8px;

                            img {
                                width: 30px;
                            }
                        }
                    }

                    .text {
                        margin-top: 12px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .search-engine {
        .big-title {
            h1 {
                font-size: 32px;
            }
        }

        .search-types {
            margin: 10px 5px;
        }
    }

    .campaign {
        .boxes {
            .box {
                margin-top: 10px;
            }
        }
    }

    .abroad-flights {
        .boxes {
            .box {
                .img {
                    height: inherit;
                }
            }
        }
    }

    .comment {
        .comment-images {
            &:before {
                left: -27px;
            }
        }
    }

    .popular {
        .domestic-list {
            ul {
                li {
                    &.bottom {
                        &:before {
                            top: 64px;
                        }

                        .img {
                            img {
                                width: inherit;
                            }
                        }
                    }

                    .city {
                        .text {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }

        .brand-list {
            ul {
                li {
                    height: 64px;

                    &.bottom {
                        &:before {
                            top: 65px;
                        }
                    }

                    .text {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .business-partners {
        .img {
            img {
                max-width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .search-types{
        ul{width:100%; 
            li{
                width:25%;

            }
        }
    }
    .more {
        margin-top: 10px;

        a {
            font-size: 14px;
        }
    }

    .title-partner, .icon-partner {
        margin-top: 20px;

        h2 {
            font-size: 22px;
        }

        img {
            width: 60px;
        }
    }

    .title-icon {
        margin-top: 20px;

        h2 {
            font-size: 22px;
        }
    }

    .sub-title {
        font-size: 18px;
    }

    .search-engine {
        .big-title {
            h1 {
                font-size: 22px;
            }
        }

        .search-types {
            margin: -5px 5px 35px 5px;

            .nav {
                > {
                    li {
                        > {
                            a {
                                font-size: 14px;
                                padding: 33px 15px 2px;
                            }
                        }
                    }
                }
            }

            .onoffswitch {
                top: 73px;
                left: 0;
            }

            .onoffswitch-switch {
                width: 29px;
                height: 19px;
            }

            .onoffswitch-inner {
                &:before {
                    height: 25px;
                    line-height: 25px;
                }

                &:after {
                    height: 25px;
                    line-height: 25px;
                }
            }
        }
    }

    .why-choose {
        .boxes {
            .box {
                width: 100% !important;
                margin-bottom: 20px;

                .icon {
                    img {
                        width: 38px;
                    }
                }

                .text {
                    height: auto !important;
                }
            }
        }
    }

    .cheap-flights {
        .title-center {
            font-size: 18px;
            margin-top: 10px;
            text-align:center;
        }

        .box {
            .price {
                margin-top: 3px;
                font-size: 20px;
            }

            .departure {
                margin-top: 5px;
            }

            .arrival {
                margin-top: 5px;
            }
        }
    }

    .abroad-flights {
        .boxes {
            .box {
                margin-top: 10px;
            }
        }
    }

    .comment {
        .comment-images {
            &:before {
                width: 66px;
                height: 45px;
                bottom: -19px;
            }
        }
    }

    .popular {
        margin-top: 20px;

        .title {
            margin-top: 15px;
        }

        .brand-list {
            ul {
                li {
                    &.bottom {
                        .city {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }

    .business-partners {
        margin-bottom: 20px;

        .title {
            margin: 10px 0 20px 0;
        }
    }
}

@media screen and (max-width: 400px) {
    .popular .domestic-list ul li.bottom .img img {
        width: 70px !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .search-engine {
        .big-title {
            h1 {
                font-size: 18px;
            }
        }
    }

    .cheap-flights {
        .box {
            .price {
                margin-top: 5px;
                font-size: 18px;
            }

            .departure {
                margin-top: 8px;
                font-size: 12px;
            }

            .arrival {
                margin-top: 8px;
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .search-engine {
        .big-title {
            h1 {
                font-size: 16px;
            }
        }

        .search-types {
            margin: -5px 5px 35px 5px;

            .nav {
                > {
                    li {
                        > {
                            a {
                                font-size: 13px;
                                padding: 33px 13px 2px;
                            }
                        }
                    }
                }
            }

            .onoffswitch {
                top: 67px;
            }
        }
    }

    .cheap-flights {
        .box {
            .brand {
                img {
                    width: 30px;
                }
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .title-partner, .icon-partner {
        h2 {
            font-size: 17px;
        }
    }

    .title-icon {
        h2 {
            font-size: 17px;
        }
    }

    .sub-title {
        font-size: 14px;
    }

    .search-engine {
        .big-title {
            margin-top: 6px;
            margin-bottom: -10px;
        }

        .search-types {
            margin: 14px 5px 30px 5px;
        }
    }

    .cheap-flights {
        .title-center {
            font-size: 14px;
        }

        .box {
            .price {
                font-size: 16px;

                img {
                    width: 10px;
                }
            }

            .line {
                &:after {
                    background-size: contain;
                    width: 16px;
                    height: 14px;
                    right: -12px;
                    top: -6px;
                }
            }

            .brand {
                img {
                    width: 25px;
                }
            }
        }
    }

    .comment {
        .comment-images {
            &:before {
                width: 50px;
                height: 36px;
            }
        }

        .comment-text {
            margin-top: 10px;

            p {
                font-size: 14px;
            }
        }
    }

    .popular {
        .title {
            font-size: 16px;
            margin-top: 5px;
        }

        .domestic-list {
            margin-top: 0px;
        }
    }
}

.popular .domestic-list a img {
    max-width: 122px;
    display: block;
    float: left;
    max-height: 63px;
}
