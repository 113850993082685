@keyframes "fpFadeInDown" {
    from {
        opacity: 0;
        transform: translate3d(0,-20px,0);
    }

    to {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}

.flatpickr-calendar {
    background: transparent;
    opacity: 0;
    display: none;
    text-align: center;
    visibility: hidden;
    padding: 0;
    animation: none;
    direction: ltr;
    border: 0;
    font-size: 14px;
    line-height: 24px;
    border-radius: 5px;
    position: absolute;
    width: 307.875px;
    box-sizing: border-box;
    touch-action: manipulation;
    background: #fff;
    box-shadow: 1px 0 0 #e6e6e6,-1px 0 0 #e6e6e6,0 1px 0 #e6e6e6,0 -1px 0 #e6e6e6,0 3px 13px rgba(0,0,0,0.08);

    .hasWeeks {
        .dayContainer {
            border-bottom: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
        }
    }

    .hasTime {
        .dayContainer {
            border-bottom: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &:before {
        position: absolute;
        display: block;
        pointer-events: none;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        left: 150px;
        border-width: 5px;
        margin: 0 -5px;
    }

    &:after {
        position: absolute;
        display: block;
        pointer-events: none;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        left: 22px;
        border-width: 4px;
        margin: 0 -4px;
    }

    &:focus {
        outline: 0;
    }
}

.flatpickr-calendar.open {
    opacity: 1;
    max-height: 640px;
    visibility: visible;
    display: inline-block;
    z-index: 99999;
    top: 76px !important;
    left: -100px !important;
}

.flatpickr-calendar.inline {
    opacity: 1;
    max-height: 640px;
    visibility: visible;
    display: block;
    position: relative;
    top: 2px;
}

.flatpickr-calendar.animate.open {
    animation: fpFadeInDown 300ms cubic-bezier(.23,1,.32,1);
}

.flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
    z-index: 999;
    display: block;
}

.flatpickr-calendar.multiMonth {
    .flatpickr-days {
        .dayContainer {
            &:nth-child(n+1) {
                .flatpickr-day.inRange {
                    &:nth-child(7n+7) {
                        box-shadow: none !important;
                    }
                }
            }

            &:nth-child(n+2) {
                .flatpickr-day.inRange {
                    &:nth-child(7n+1) {
                        box-shadow: -2px 0 0 #7cb4d2,5px 0 0 #7cb4d2;
                    }
                }
            }
        }
    }
}

.flatpickr-calendar.showTimeInput.hasTime {
    .flatpickr-time {
        height: 40px;
        border-top: 1px solid #7cb4d2;
    }
}

.flatpickr-calendar.noCalendar.hasTime {
    .flatpickr-time {
        height: auto;
    }
}

.flatpickr-calendar.rightMost {
    &:before {
        left: auto;
        right: 22px;
    }

    &:after {
        left: auto;
        right: 22px;
    }
}

.flatpickr-calendar.arrowTop {
    &:before {
        bottom: 100%;
        border-bottom-color: #7cb4d2;
    }

    &:after {
        bottom: 100%;
    }
}

.flatpickr-calendar.arrowBottom {
    &:before {
        top: 100%;
        border-top-color: #7cb4d2;
    }

    &:after {
        top: 100%;
        border-top-color: #fff;
    }
}

.flatpickr-wrapper {
    position: relative;
    display: inline-block;
}

.flatpickr-months {
    display: flex;

    .flatpickr-month {
        background: transparent;
        color: rgba(0,0,0,0.9);
        fill: rgba(0,0,0,0.9);
        height: 28px;
        line-height: 1;
        text-align: center;
        position: relative;
        user-select: none;
        overflow: hidden;
        flex: 1;
    }

    .flatpickr-prev-month {
        text-decoration: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        line-height: 16px;
        height: 28px;
        padding: 10px;
        z-index: 3;
        color: rgba(0,0,0,0.9);
        fill: rgba(0,0,0,0.9);

        i {
            position: relative;
        }

        &:hover {
            color: #959ea9;

            svg {
                fill: #f64747;
            }
        }

        svg {
            width: 14px;
            height: 14px;

            path {
                transition: fill .1s;
                fill: inherit;
            }
        }
    }

    .flatpickr-next-month {
        text-decoration: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        line-height: 16px;
        height: 28px;
        padding: 10px;
        z-index: 3;
        color: rgba(0,0,0,0.9);
        fill: rgba(0,0,0,0.9);

        i {
            position: relative;
        }

        &:hover {
            color: #959ea9;

            svg {
                fill: #f64747;
            }
        }

        svg {
            width: 14px;
            height: 14px;

            path {
                transition: fill .1s;
                fill: inherit;
            }
        }
    }

    .flatpickr-prev-month.disabled {
        display: none;
    }

    .flatpickr-next-month.disabled {
        display: none;
    }

    .flatpickr-prev-month.flatpickr-prev-month {
        left: 0;
    }

    .flatpickr-next-month.flatpickr-prev-month {
        left: 0;
    }

    .flatpickr-prev-month.flatpickr-next-month {
        right: 0;
    }

    .flatpickr-next-month.flatpickr-next-month {
        right: 0;
    }
}

.numInputWrapper {
    position: relative;
    height: auto;

    input {
        display: inline-block;
        width: 100%;

        &::-ms-clear {
            display: none;
        }
    }

    span {
        display: inline-block;
        position: absolute;
        right: 0;
        width: 14px;
        padding: 0 4px 0 2px;
        height: 50%;
        line-height: 50%;
        opacity: 0;
        cursor: pointer;
        border: 1px solid rgba(57,57,57,0.15);
        box-sizing: border-box;

        &:hover {
            background: rgba(0,0,0,0.1);
        }

        &:active {
            background: rgba(0,0,0,0.2);
        }

        &:after {
            display: block;
            content: "";
            position: absolute;
        }

        svg {
            width: inherit;
            height: auto;

            path {
                fill: rgba(0,0,0,0.5);
            }
        }
    }

    span.arrowUp {
        top: 0;
        border-bottom: 0;

        &:after {
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid rgba(57,57,57,0.6);
            top: 26%;
        }
    }

    span.arrowDown {
        top: 50%;

        &:after {
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid rgba(57,57,57,0.6);
            top: 40%;
        }
    }

    &:hover {
        background: rgba(0,0,0,0.05);

        span {
            opacity: 1;
        }
    }
}

.flatpickr-current-month {
    font-size: 135%;
    line-height: inherit;
    font-weight: 300;
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    padding: 6.16px 0 0 0;
    line-height: 1;
    height: 28px;
    display: inline-block;
    text-align: center;
    transform: translate3d(0,0,0);

    span.cur-month {
        font-family: inherit;
        font-weight: 700;
        color: inherit; 
        padding: 0;
        display:block;  

        &:hover {
            background: rgba(0,0,0,0.05);
        }
    }

    .numInputWrapper {
        width: 6ch;
        width: 7ch\0;
        display: inline-block;

        span.arrowUp {
            &:after {
                border-bottom-color: rgba(0,0,0,0.9);
            }
        }

        span.arrowDown {
            &:after {
                border-top-color: rgba(0,0,0,0.9);
            }
        }
    }

    input.cur-year {
        background: transparent;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: inherit;
        cursor: text;
        padding: 0 0 0 .5ch;
        margin: 0;
        display: inline-block;
        font-size: inherit;
        font-family: inherit;
        font-weight: 300;
        line-height: inherit;
        height: auto;
        border: 0;
        border-radius: 0;
        vertical-align: initial;

        &:focus {
            outline: 0;
        }
    }

    input.cur-year[disabled] {
        font-size: 100%;
        color: rgba(0,0,0,0.5);
        background: transparent;
        pointer-events: none;

        &:hover {
            font-size: 100%;
            color: rgba(0,0,0,0.5);
            background: transparent;
            pointer-events: none;
        }
    }
}

.flatpickr-weekdays {
    background: transparent;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    height: 28px;

    .flatpickr-weekdaycontainer {
        display: flex;
        flex: 1;
    }
}

span.flatpickr-weekday {
    cursor: default;
    font-size: 90%;
    background: transparent;
    color: rgba(0,0,0,0.54);
    line-height: 1;
    margin: 0;
    text-align: center;
    display: block;
    flex: 1;
    font-weight: bolder;
}

.dayContainer {
    padding: 1px 0 0 0;
    padding: 0;
    outline: 0;
    text-align: left;
    width: 309.875px;
    min-width: 307.875px;
    max-width: 307.875px;
    box-sizing: border-box;
    display: inline-block;
    justify-content: space-around;
    transform: translate3d(0,0,0);
    opacity: 1;

    + {
        .dayContainer {
            box-shadow: -1px 0 0 #7cb4d2;
        }
    }
}

.flatpickr-weeks {
    padding: 1px 0 0 0;
}

.flatpickr-days {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    width: 307.875px;

    &:focus {
        outline: 0;
    }
}

.flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: 100%;
    padding: 3px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #393939;
    cursor: pointer;
    font-weight: 400;
    width: 14.2857143%;
    flex-basis: 14.2857143%;
    max-width: 45px;
    height: 43px;
    line-height: 36px;
    margin: 0;
    display: inline-block;
    position: relative;
    justify-content: center;
    text-align: center;

    &:hover {
        cursor: pointer;
        outline: 0;
        background: #7cb4d2;
        border-color: #7cb4d2;
        color: #fff !important;
    }

    &:focus {
        cursor: pointer;
        outline: 0;
        background: #7cb4d2;
        border-color: #7cb4d2;
    }
}

.flatpickr-day.inRange {
    cursor: pointer;
    outline: 0;
    background: #7cb4d2;
    border-color: #7cb4d2;
    border-radius: 0;
    box-shadow: -5px 0 0 #7cb4d2,5px 0 0 #7cb4d2;
}

.flatpickr-day.prevMonthDay.inRange {
    cursor: pointer;
    outline: 0;
    background: #7cb4d2;
    border-color: #7cb4d2;
    color: #393939;
}

.flatpickr-day.nextMonthDay.inRange {
    cursor: pointer;
    outline: 0;
    background: #7cb4d2;
    border-color: #7cb4d2;
    color: #393939;
}

.flatpickr-day.today.inRange {
    cursor: pointer;
    outline: 0;
    background: #7cb4d2;
    border-color: #7cb4d2;
}

.flatpickr-day.prevMonthDay.today.inRange {
    cursor: pointer;
    outline: 0;
    background: #7cb4d2;
    border-color: #7cb4d2;
}

.flatpickr-day.nextMonthDay.today.inRange {
    cursor: pointer;
    outline: 0;
    background: #7cb4d2;
    border-color: #7cb4d2;
}

.flatpickr-day.prevMonthDay {
    &:hover {
        cursor: pointer;
        outline: 0;
        background: #7cb4d2;
        border-color: #7cb4d2;
    }

    &:focus {
        cursor: pointer;
        outline: 0;
        background: #7cb4d2;
        border-color: #7cb4d2;
    }

    color: rgba(57,57,57,0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
}

.flatpickr-day.nextMonthDay {
    &:hover {
        cursor: pointer;
        outline: 0;
        background: #7cb4d2;
        border-color: #7cb4d2;
        color: #fff !important;
    }

    &:focus {
        cursor: pointer;
        outline: 0;
        background: #7cb4d2;
        border-color: #7cb4d2;
    }

    color: rgba(57,57,57,0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
    color: #b8bbbc !important 


}

.flatpickr-day.today {
    border-color: #959ea9;

    &:hover {
        border-color: #959ea9;
        background: #959ea9;
        color: #fff;
    }

    &:focus {
        border-color: #959ea9;
        background: #959ea9;
        color: #fff;
    }
}

.flatpickr-day.selected {
    background: #569ff7;
    box-shadow: none;
    color: #fff !important;
    border-color: #569ff7;

    &:focus {
        background: #569ff7;
        box-shadow: none;
        color: #fff;
        border-color: #569ff7;
    }

    &:hover {
        background: #569ff7;
        box-shadow: none;
        color: #fff;
        border-color: #569ff7;
    }
}

.flatpickr-day.startRange {
    background: #569ff7;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;

    &:focus {
        background: #569ff7;
        box-shadow: none;
        color: #fff;
        border-color: #569ff7;
    }

    &:hover {
        background: #569ff7;
        box-shadow: none;
        color: #fff;
        border-color: #569ff7;
    }
}

.flatpickr-day.endRange {
    background: #569ff7;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;

    &:focus {
        background: #569ff7;
        box-shadow: none;
        color: #fff;
        border-color: #569ff7;
    }

    &:hover {
        background: #569ff7;
        box-shadow: none;
        color: #fff;
        border-color: #569ff7;
    }
}

.flatpickr-day.selected.inRange {
    background: #569ff7;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
}

.flatpickr-day.startRange.inRange {
    background: #569ff7;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
}

.flatpickr-day.endRange.inRange {
    background: #569ff7;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
}

.flatpickr-day.selected.prevMonthDay {
    background: #569ff7;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
}

.flatpickr-day.startRange.prevMonthDay {
    background: #569ff7;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
}

.flatpickr-day.endRange.prevMonthDay {
    background: #569ff7;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
}

.flatpickr-day.selected.nextMonthDay {
    background: #569ff7;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
}

.flatpickr-day.startRange.nextMonthDay {
    background: #569ff7;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
}

.flatpickr-day.endRange.nextMonthDay {
    background: #569ff7;
    box-shadow: none;
    color: #fff;
    border-color: #569ff7;
}

.flatpickr-day.selected.startRange {
    border-radius: 50px 0 0 50px;

    + {
        .endRange {
            &:not(:nth-child(7n+1)) {
                box-shadow: -10px 0 0 #7cb4d2;
            }
        }
    }
}

.flatpickr-day.startRange.startRange {
    border-radius: 50px 0 0 50px;

    + {
        .endRange {
            &:not(:nth-child(7n+1)) {
                box-shadow: -10px 0 0 #7cb4d2;
            }
        }
    }
}

.flatpickr-day.endRange.startRange {
    border-radius: 50px 0 0 50px;

    + {
        .endRange {
            &:not(:nth-child(7n+1)) {
                box-shadow: -10px 0 0 #569ff7;
            }
        }
    }
}

.flatpickr-day.selected.endRange {
    border-radius: 0 50px 50px 0;
}

.flatpickr-day.startRange.endRange {
    border-radius: 0 50px 50px 0;
}

.flatpickr-day.endRange.endRange {
    border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange.endRange {
    border-radius: 50px;
}

.flatpickr-day.startRange.startRange.endRange {
    border-radius: 50px;
}

.flatpickr-day.endRange.startRange.endRange {
    border-radius: 50px;
}

.flatpickr-day:not(.disabled) {
    font-weight: 500;
    color: #2da2cf;
}

 

.flatpickr-day.disabled {
    color: rgba(57,57,57,0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
    cursor: not-allowed;
    color: rgba(57,57,57,0.1);

    &:hover {
        color: rgba(57,57,57,0.3);
        background: transparent;
        border-color: transparent;
        cursor: default;
        cursor: not-allowed;
        color: rgba(57,57,57,0.1);
    }
}

.flatpickr-day.notAllowed {
    color: rgba(57,57,57,0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
}

.flatpickr-day.notAllowed.prevMonthDay {
    color: rgba(57,57,57,0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
}

.flatpickr-day.notAllowed.nextMonthDay {
    color: rgba(57,57,57,0.3);
    background: transparent;
    border-color: transparent;
    cursor: default;
}

.flatpickr-day.week.selected {
    border-radius: 0;
    box-shadow: -5px 0 0 #569ff7,5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
    visibility: hidden;
}

.rangeMode {
    .flatpickr-day {
        margin-top: 1px;
    }
}

.flatpickr-weekwrapper {
    display: inline-block;
    float: left;

    .flatpickr-weeks {
        padding: 0 12px;
        box-shadow: 1px 0 0 #7cb4d2;
    }

    .flatpickr-weekday {
        float: none;
        width: 100%;
        line-height: 28px;
    }

    span.flatpickr-day {
        display: block;
        width: 100%;
        max-width: none;
        color: rgba(57,57,57,0.3);
        background: transparent;
        cursor: default;
        border: none;

        &:hover {
            display: block;
            width: 100%;
            max-width: none;
            color: rgba(57,57,57,0.3);
            background: transparent;
            cursor: default;
            border: none;
        }
    }
}

.flatpickr-innerContainer {
    display: block;
    display: flex;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
}

.flatpickr-rContainer {
    display: inline-block;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.flatpickr-time {
    text-align: center;
    outline: 0;
    display: block;
    height: 0;
    line-height: 40px;
    max-height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    .numInputWrapper {
        flex: 1;
        width: 40%;
        height: 40px;
        float: left;

        span.arrowUp {
            &:after {
                border-bottom-color: #393939;
            }
        }

        span.arrowDown {
            &:after {
                border-top-color: #393939;
            }
        }
    }

    input {
        background: transparent;
        box-shadow: none;
        border: 0;
        border-radius: 0;
        text-align: center;
        margin: 0;
        padding: 0;
        height: inherit;
        line-height: inherit;
        color: #393939;
        font-size: 14px;
        position: relative;
        box-sizing: border-box;

        &:focus {
            outline: 0;
            border: 0;
            background: #f3f3f3;
        }

        &:hover {
            background: #f3f3f3;
        }
    }

    input.flatpickr-hour {
        font-weight: bold;
    }

    input.flatpickr-minute {
        font-weight: 400;
    }

    input.flatpickr-second {
        font-weight: 400;
    }

    .flatpickr-time-separator {
        height: inherit;
        display: inline-block;
        float: left;
        line-height: inherit;
        color: #393939;
        font-weight: bold;
        width: 2%;
        user-select: none;
        align-self: center;
    }

    .flatpickr-am-pm {
        height: inherit;
        display: inline-block;
        float: left;
        line-height: inherit;
        color: #393939;
        font-weight: bold;
        width: 2%;
        user-select: none;
        align-self: center;
        outline: 0;
        width: 18%;
        cursor: pointer;
        text-align: center;
        font-weight: 400;

        &:hover {
            background: #f3f3f3;
        }

        &:focus {
            background: #f3f3f3;
        }
    }
}

.flatpickr-time.hasSeconds {
    .numInputWrapper {
        width: 26%;
    }
}

.flatpickr-time.time24hr {
    .numInputWrapper {
        width: 49%;
    }
}

.flatpickr-input[readonly] {
    cursor: pointer;
}
body {
    .date {
        .screenSettings:before {
            transform: rotate(180deg);
            top: 100% !important;
            bottom: initial !important;
        }


        .screenSettings {
            /*top: -269px !important;*/ 
            .flatpickr-day {
                max-width: 35px !important;
                height: 33px !important;
                line-height: 26px !important;
            }

            .flatpickr-innerContainer {
                padding-left: 11px !important;
            }

            .flatpickr-weekdays .flatpickr-weekdaycontainer {
                max-width: 284px;
            }
        }
    }
}


//Resposive Baslangic
@media screen and (max-width: 767px) {
    .flatpickr-calendar.open {
        left: 0 !important;
    }
}

    @media screen and (max-width: 480px) {
        .flatpickr-calendar.arrowTop:before {
            top: 100% !important;
        }

        .flatpickr-calendar:before {
            display: none !important;
        }

        .flatpickr-calendar {
            width: -webkit-calc(100vw - 40px);
            width: calc(100vw - 40px);
        }

        .flatpickr-rContainer {
            width: 100% !important;
        }

        .flatpickr-days {
            width: 100% !important;
            align-items: center;
            justify-content: center;
        }

        .dayContainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 310px !important;
        }

        .flatpickr-day {
            width: calc(100% / 7);
            line-height: 43px;
            margin-bottom: 6px;
        }

        .flatpickr-current-month span.cur-month {
            color: #7cb4d2;
            font-size: 20px;
        }

        span.flatpickr-weekday {
            font-size: 16px;
            color: #7cb4d2;
            text-align: center;
        }

        .flatpickr-weekdays {
            height: 45px;
            margin: auto;
            max-width: 310px;
        }

        .flatpickr-day {
            max-width: 50px;
            height: 50px;
            color: #808380;
        }
    }

    @media screen and (max-width: 320px) {
        .flatpickr-weekdays {
            height: 35px;
        }

        span.flatpickr-weekday {
            font-size: 14px;
        }

        .dayContainer {
            min-width: 100%;
        }

        .flatpickr-day {
            line-height: 36px;
            height: 42px;
        }
    }
