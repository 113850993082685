 .MenuOpen {
    header{
            z-index:1000;
    } 
}
header {
    position: relative;

    .navbar {
        .logo {
            display: none;
        }
    }

    .ml-2 {
        margin-left: 15px !important;
    }

    .ml-0 {
        margin-left: 0 !important;
    }


    .hamburgher-menu {
        display: none;
    }

    .navbar-nav {
        margin-left: 15px;

        > li {
            > a {
                color: #808380;
            }
        }
    }

    .nav > li > a {
        padding: 10px 7px;
        font-weight: 500;
    }

    .navbar-right {
    }

    .navbar {
        border: 0;
        margin-bottom: 0;

        .logo {
            margin-top: 26px;
            display: inline-block;

            img {
                max-width: 116px;
            }
        }

        ul {
            li {
                img {
                    margin-right: 9px;
                    margin-top: -4px;
                }
            }

            li.phone {
                a {
                    font-size: 22px;
                    font-weight: bold;
                    letter-spacing: -1.1px;
                    color: #fff;
                    text-shadow: 0 1px 1px #15638c;
                    padding-bottom: 0;

                    img {
                        margin-right: 5px;
                        max-width: 30px;
                        float: left;
                        margin-top: -3px;
                    }
                }
            }
        }
    }

    .nav {
        > li {
            > a {
                &:focus {
                    background-color: transparent !important;
                }
            }

            margin-top: 25px;
        }
    }

    .navbar-right {
        > li:last-child {
        }
    }

    .language {
        position: absolute;
        right: 0;
        top: 0;
        width: 25px;
        height: 18px;
        border: 1px solid #808380;
        border-radius: 5px;
        font-size: 12px;
        margin-top: 38px;
        text-align: center;
        margin-right: 20px;
        font-weight: 700;
        display: none;
    }
}

.navbar-nav {
    > li {
        > a {
            &:hover {
                background-color: transparent !important;
            }
        }
    }
}


.btnLoading {
    font-size: 0 !important; 
    &:after {
        content: "";
        display: block; 
        background: url(../images/bus/ticket/loading-1.svg) no-repeat;  
        background-size:90%; 
    } 
} 

@media screen and (max-width: 1200px) {
    header {
        .navbar {
            ul {
                li {  
                    &.phone {
                        a {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .navbar-nav {
        > li {
            font-size: 12px;

            a {
                padding: 10px 4px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    header {
        .navbar-toggle {
            .icon-bar {
                background: #16abe2;
            }
        }
    }
}




@media screen and (max-width: 992px) { /*header menu mobil gorunumu*/
    header {

        .container > .navbar-header {
            margin-left: 0;
            margin-right: 0;
            position: relative;
            z-index: 999;
        }

        .web-menu {
            display: none;
        }

        .hamburgher-menu {
            display: block;

            .navbar {

                .phone-2 {
                    display: inline-block;
                    float: right;
                    position: relative;
                    width: 82px !important;
                    margin-top: 16px;

                    a {
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: -1.1px;
                        color: #136288;
                        line-height: 6px; 
                        img {
                            position: absolute;
                            left: -25px;
                            top: -6px;
                            bottom: 0;
                            margin: auto;
                            max-width: 20px;
                        } 
                        p {
                            font-size: 13px;
                            text-align: left;
                            margin-bottom: 0;
                            line-height: 19px;
                            margin-top: -5px;
                            margin-left: -13px;
                            color: #136288;
                            box-shadow: 0 !important;
                            float: right;
                            margin-top: -5px;
                        }
                    }
                }

                .logo-mobile {
                    display: inline-block;
                    margin-top: 14px;
                    position: relative;
                    z-index: 100;

                    img {
                        max-width: 116px;
                    }
                }

                .slide-content {
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    transition: opacity .2s ease-in-out;
                    overflow: hidden;
                    display: none;
                    z-index: 9000;
                }

                .right-slide-bar {
                    background: #fff;
                    position: fixed;
                    overflow: hidden;
                    display: none;
                    height: 100%;
                    width: 300px;
                    right: 0px;
                    bottom: 0;
                    top: 0;
                    z-index: 99999;

                    .close-menu {
                        font-size: 20px;
                        font-weight: bold;
                        color: #71a8bc;
                        padding: 20px;
                        line-height: 13px;
                        border-bottom: 1px solid #dcdcdc;
                        font-size: 32px;
                        cursor: pointer;
                    }

                    ul {
                        li {
                            img {
                                margin-left: 10px;
                                margin-top: 0;
                                width: 15px;
                                height: 20px;
                            }

                            &.phone {
                                font-size: 19px;
                                background-color: #7cb4d2;

                                a {
                                    color: #fff;
                                    padding: 12px;
                                    font-size: 20px;

                                    &:hover {
                                        background-color: transparent !important;
                                    }
                                }

                                img {
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }
                    }
                }
            }

            .nav {
                > li {
                    font-size: 16px;
                    margin-top: 0;
                    border-bottom: 1px solid #dcdcdc;
                    line-height: 24px;
                    font-weight: 500;


                    > a {
                        margin: 0;
                        padding: 20px;
                        line-height: 13px;
                        color: #7cb4d2;

                        &:focus {
                            background-color: #eee !important;
                        }

                        &:hover {
                            background-color: #eee !important;
                        }
                    }
                }

                > li:last-child {
                    display: none !important;
                }
            }

            .navbar-toggle {
                z-index: 10;
                margin: 0;
                top: 14px;
                padding: 8px;
            }

            .navbar-nav {
                text-align: right;
                margin: 0;
                position: relative;
            }

            .navbar-right {
                float: none !important;
                text-align: right;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    header {
        .hamburgher-menu {
            .navbar {
                .phone-2 {
                    margin-top: 17px;
                }

                .logo {
                    margin-top: 5px;
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    header {
        .hamburgher-menu {
            .navbar {
                .navbar {
                    .logo-mobile {
                        img {
                            margin-left: -15px;
                        }
                    }
                }
            }
        }
    }
}
 
 
@media screen and (max-width: 767px) {
    section {
        position: relative;
      
    }
    section.airline-custom{
        margin-top:0 !important;
    }
}




@media screen and (max-width: 320px) {
    header {
        .hamburgher-menu {
            .navbar {
                .phone-2 {
                     
                }
            }

            .navbar {
                .right-slide-bar {
                    width: 220px;
                }
            }

            .nav > li {
                font-size: 14px;
            }
        }
    }
}
