.search {
    position: relative;

    .voyage {
        position: absolute;
        top: 68px;
        left: 4px;
        z-index: 11;
        width: 400px;

        .airpport-name {
            margin: 0;
            padding: 0;

            .list-group-item {
                border-radius: inherit;

                &.active {
                    border-color: silver;
                }
            }

            li {
                position: relative;
                font-weight: 400;
                color: #808380;
                cursor: pointer;
                /*&:first-child {
                    background: #77abbf;
                    color: #fff;
                }*/
                &:before {
                    background: url(../images/search/bus-ok.svg) no-repeat;
                    position: absolute;
                    background-size: contain;
                    content: "";
                    width: 14px;
                    height: 10px;
                    left: 10px;
                    bottom: 13px;
                }

                &.active {
                    background: #77abbf;
                    color: #fff !important;
                    border-color: silver;

                    &:before {
                        background: url(../images/search/bus-ok-white.svg) no-repeat;
                        position: absolute;
                        background-size: contain;
                        content: "";
                        width: 14px;
                        height: 10px;
                        left: 10px;
                        bottom: 13px;
                    }
                }

                &:hover {
                    background: #77abbf;
                    color: #fff !important;

                    &:before {
                        background: url(../images/search/bus-ok-white.svg) no-repeat;
                        position: absolute;
                        background-size: contain;
                        content: "";
                        width: 14px;
                        height: 10px;
                        left: 10px;
                        bottom: 13px;
                    }
                }
            }

            .list-group-item {
                padding: 8px 23px;
                font-weight: 500;
            }

            .list-group-item.tumu {
                &:before {
                    background: url(../images/header/ucak-menu.svg) no-repeat;
                    background-size: 78%;
                    bottom: 12px;
                    left: 6px;
                }
            }

            .list-group-item.tumu.active {
                &:before {
                    background: url(../images/search-menu/ucak-beyaz.svg) no-repeat !important;
                    background-size: 84% !important;
                }
            }

            li.list-group-item.tumu:hover {
                &:before {
                    background: url(../images/search-menu/ucak-beyaz.svg) no-repeat !important;
                    background-size: 84% !important;
                }
            }
        }
    }

    .up-button {
        width: 225px;
        margin: 10px 0px;
        cursor: pointer;
        display: block;
        color: #7cb4d2;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.43;
        text-align: center;

        &:after {
            background: url(../images/boarding/up.png) no-repeat;
            position: absolute;
            content: "";
            height: 10px;
            width: 16px;
            top: 16px;
            left: 8px;
        }
    }

    .list {
        .nav-tabs {
            border-bottom: none;
        }
    }

    .form-group {
        position: relative;
        margin: 0 -10px !important;
        cursor: pointer;
    }

    .search-input {
        position: relative;

        &:before {
            display: inline-block;
            content: "";
            position: absolute;
            left: 18px;
            top: 22px;
            width: 20px;
            height: 23px;
            background: url(../images/search/konum.svg) no-repeat;
        }

        .form-control {
            height: 68px;
            border-radius: 5px;
            background-color: #fffffe;
            box-shadow: 0 0 4px 0 rgba(21, 99, 140, 0.33);
            border: 0;
            padding-left: 50px;
            font-size: 20px;
            color: #808380;
            font-weight: 500;
            margin-top: 8px;
            -webkit-appearance: none;
        }
    }

    .airport-list {
        position: absolute;
        top: 68px;
        left: -11px;
        min-width: 353px;
        z-index: 11;

        ul {
            li {
                font-weight: 400;
                color: #808380;
                /*&:first-child {
                    background: #77abbf;
                    color: #fff;
                }*/
                &:before {
                    background: url(/images/search/plane.png) no-repeat;
                    position: absolute;
                    content: "";
                    width: 14px;
                    height: 14px;
                    left: 5px;
                    bottom: 17px;
                }

                &:hover {
                    background: #77abbf;
                    color: #fff;

                    &:after {
                        background: url(/images/search/plane-white.png) no-repeat;
                        position: absolute;
                        content: "";
                        width: 14px;
                        height: 14px;
                        left: 5px;
                        bottom: 17px;
                    }
                }
            }

            .list-group-item {
                padding: 14px 23px;
            }
        }
    }

    .swap-btn {
        position: relative;
        cursor: pointer;
        width: 100%;
        height: 68px;
        border-radius: 5px;
        background-color: #fffffe;
        box-shadow: 0 0 4px 0 rgba(21, 99, 140, 0.33);
        margin-top: 8px;

        &:before {
            display: inline-block;
            content: "";
            position: absolute;
            width: 20px;
            height: 29px;
            left: 50%;
            top: 23px;
            margin-left: -10px;
            background: url(../images/search/degistir.svg) no-repeat;
        }
    }

    .passenger {
        position: relative;
        width: 100%;
        height: 68px;
        cursor: pointer;
        margin-top: 8px;
        border-radius: 5px;
        background-color: #fffffe;
        box-shadow: 0 0 4px 0 rgba(21, 99, 140, 0.33);


        .passenger-icon {
            img {
                margin-left: 20px;
                margin-top: 18px;
            }
        }

        .total-passenger {
            text-align: center;
            margin: 15px auto;

            .passengers {
                position: relative;
                font-size: 16px;
                color: #808380;
                display: inline-block;
                line-height: 13px;
                font-weight: 400;

                &:before {
                    position: absolute;
                    content: "";
                    background: url(/images/search/yetiskin-tek.png) no-repeat;
                    width: 18px;
                    height: 36px;
                    left: -20px;
                    top: -1px;
                }
            }

            .number {
                font-size: 17px;
                font-weight: 400;
                color: #808380;
                display: inline-block;
                line-height: 13px;
            }

            .text {
                display: inline-block;
                font-size: 17px;
                font-weight: 400;
                color: #808380;
                line-height: 13px;
            }
        }
    }

    .passenger-box {
        background-color: #fffffe;
        box-shadow: 0 0 4px 0 rgba(21, 99, 140, 0.33);
        padding: 8px 13px 8px 23px;
        display: inline-block;
        position: absolute;
        z-index: 100;
        width: 220px;
        margin-left: -18px;
        border-radius: 5px;
        margin-top: 10px;
        display: none;

        .arrow {
            width: 20px;
            height: 20px;
            position: absolute;
            top: -20px;
            left: 50%;
            margin-left: -10px;
            overflow: hidden;

            &:after {
                content: "";
                position: absolute;
                width: 10px;
                height: 10px;
                background: #fff;
                transform: rotate(45deg);
                top: 15px;
                left: 3px;
                box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
            }
        }


        ul {
            margin: 0;
            padding: 0;

            li {
                width: 100%;
                display: block;
                clear: both;
                margin: 9px;
                position: relative;
            }
        }

        .count {
            font-size: 20px;
            color: #808380;
            display: inline-block;
            margin-right: 5px;
            width: 20px;
            text-align: center;

            &:before {
                display: inline-block;
                content: "";
                position: absolute;
                left: -17px;
                top: 5px;
                width: 20px;
                height: 23px;
            }
        }

        .spinner {
            display: inline-block;

            .minus {
                width: 15px;
                height: 15px;
                background: url(../images/search/eksi.svg) no-repeat;
                display: inline-block;
                cursor: pointer;
            }

            .text {
                width: 100px;
                text-align: center;
                font-size: 16px;
                color: #808380;
                display: inline-block;
            }

            .plus {
                width: 15px;
                height: 15px;
                background: url(../images/search/arti.svg) no-repeat;
                display: inline-block;
                cursor: pointer;
            }
        }

        .close-button {
            width: 100%;
            height: 30px;
            line-height: 19px;
            font-weight: 500;
            margin-left: -5px;
            background: #71a8bc;
            color: #fff;
        }
    }

    .adult {
        &:before {
            background: url(../images/search/yetiskin.svg) no-repeat;
        }
    }

    .child {
        &:before {
            background: url(../images/search/cocuk.svg) no-repeat;
        }
    }

    .infant {
        &:before {
            background: url(../images/search/bebek.svg) no-repeat;
        }
    }

    .old {
        &:before {
            background: url(../images/search/yasli.svg) no-repeat;
        }
    }

    .student {
        &:before {
            background: url(../images/search/ogrenci.svg) no-repeat;
        }
    }

    .btn {
        height: 68px;
        border-radius: 5px;
        background-color: #fffffe;
        box-shadow: 0 0 4px 0 rgba(21, 99, 140, 0.33);
        border: 0;
    }

    .btn-block {
        background-color: #e31837;
        outline: 0;
    }

    .btn-block span {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
    }

    .passenger-lb {
        position: absolute;
        top: 20px;
        border: solid 1px #dedede;
        padding: 20px;
        z-index: 20;
        height: 233px;
        display: inline-block;
        overflow: hidden;
        margin-left: -30px;
        background-color: #fffffe;
        box-shadow: 0 0 4px 0 rgba(21, 99, 140, 0.33);
    }

    #arrival.passive {
        .date-title, .plus {
            opacity: 0.7;
        }
    }

    .datepicker {
        height: 68px;
        border: 0;
        border-radius: 5px;
        margin-top: 8px;
        background-color: #fffffe;
        box-shadow: 0 0 4px 0 rgba(21, 99, 140, 0.33);
        position: relative;

        &:before {
            width: 1px;
            height: 53px;
            background: #e7e7e1;
            display: inline-block;
            content: "";
            position: absolute;
            left: 50%;
            top: 7px;
        }

        .date {
            float: left;
            width: 50%;
            padding: 8px 0;
            box-sizing: border-box;
            text-align: center;
            position: relative;

            .close-button {
                position: relative;
                display: none;

                &:before {
                    position: absolute;
                    content: "x";
                    font-weight: bold;
                    color: #fffffe;
                    line-height: 13px;
                    font-size: 15px;
                    right: 1px;
                    cursor: pointer;
                    top: -30px;
                    padding: 1px 4px 2px 4px;
                    border-radius: 100%;
                    background: #71a8bc;
                }
            }

            .plus {
                &:before {
                    background: url(../images/search/plus.svg) no-repeat;
                    content: "";
                    position: absolute;
                    width: 16px;
                    top: 20px;
                    left: 37px;
                    height: 39px;
                }
            }

            .minus {
                display: none;

                &:after {
                    background: url(../images/search/close-2.png) no-repeat;
                    content: "";
                    position: absolute;
                    width: 13px;
                    height: 13px;
                    top: 22px;
                    right: 3px;
                }
            }

            input[type="text"] {
                display: none;
            }

            .date-title {
                font-size: 17px;
                color: #808380;
                line-height: 17px;
                margin-bottom: 8px;
            }

            .date-number {
                font-size: 31px;
                font-weight: 500;
                letter-spacing: -2px;
                color: #77abbf;
                display: inline-block;
                line-height: 23px;
            }

            .date-block {
                font-size: 13px;
                color: #808380;
                display: inline-block;
                line-height: 14px;
            }
        }
    }

    .right-ok {
        margin-top: 8px;
    }
}
.search-engine{
    .search{
        display:block !important;
    }
}
@media screen and (max-width: 1280px) {
    .search {
        .datepicker {
            .date {
                .title {
                    margin-bottom: 7px;
                    font-size: 15px;
                }

                .date-number {
                    line-height: 21px;
                    font-size: 30px;
                }

                .plus {
                    &:before {
                        left: 30px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .mobileClear{clear:both !important;}
}

    @media screen and (max-width: 1200px) {
        .search {

            .up-button {
                margin: 0; 

                &:after {
                    top: 6px;
                }
            }

            .passenger {
                .total-passenger {
                    margin: 15px auto;
                }
            }

            .passenger-box {
                margin-left: 35px;
            }

            .datepicker {
                .date {
                    .date-title {
                        margin-bottom: 5px;
                    }

                    .plus {
                        &:before {
                            top: 22px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        #search-engine {
            .search-types {
                .onoffswitch {
                    right: 0 !important;
                }
            }
        }

        .search {
            .search-input {
                .form-control {
                    font-size: 16px;
                }
            }


            .datepicker {
                .date {
                    .plus {
                        &:before {
                            left: 90px;
                        }
                    }
                }

                .flatpickr-calendar {
                    &.open {
                    }
                }
            }
        }
    }

    @media screen and (max-width: 992px) {
        .search {
            .search-input {
                .form-control {
                    font-size: 18px;
                }
            }

            .passenger-box {
                margin-left: 105px;
            }

            .datepicker {
                .date {
                    .date-number {
                        font-size: 28px;
                        line-height: 30px;
                    }

                    .date-block {
                        line-height: 11px;
                    }

                    .plus {
                        &:before {
                            left: 66px;
                        }
                    }
                }
            }
        }
    }


    @media screen and (max-width: 992px) {
        .search {
            .passenger-box {
                margin-left: 38px;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .search {
            display: none;
        }

        .swap-button {
            display: none !important;
        } 
    }

    @media screen and (max-width: 640px) {
        .swap-button {
            display: none;
        }

        .search {
            .flight-input {
                margin-top: 8px;
            }

            .passenger-box {
                margin-left: 205px;
            }

            .datepicker {
                .date {
                    .plus {
                        &:before {
                            left: 140px;
                        }
                    }
                }

                .flatpickr-calendar {
                    &.open {
                        left: 0px !important;
                    }
                }

                #arrival {
                    .flatpickr-calendar {
                        left: inherit !important;
                        right: 0 !important;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        .search {
            .search-input {
                margin-top: 8px;

                &:before {
                    top: 22px;
                    left: 10px;
                }

                .form-control {
                    padding-left: 32px;
                    font-size: 16px;
                }
            }

            .passenger-box {
                margin-left: 85px;
            }

            .passenger {
                margin-top: 8px;
            }

            .datepicker {
                margin-top: 8px;

                .flatpickr-calendar.open {
                }

                .date {
                    .plus {
                        &:before {
                            left: 103px;
                        }
                    }
                }
            }

            .btn {
                height: 50px;
            }
        }
    }

    @media screen and (max-width: 414px) {
        .search {
            .datepicker {
                .date {
                    .plus {
                        &:before {
                            left: 85px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 375px) {
        .search {
            .passenger-box {
                margin-left: 58px;
            }

            .datepicker {
                .date {
                    .plus {
                        &:before {
                            left: 75px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 320px) {
        .search {
            .search-input {
                .form-control {
                    font-size: 14px;
                }
            }

            .passenger-box {
                margin-left: 30px;
            }

            .datepicker {
                .date {
                    .plus {
                        &:before {
                            left: 62px;
                        }
                    }
                }
            }
        }
    }
