footer-fixed {
    display: none;
    width: 100%;
    text-align: center;
    color: #fff;
    font-weight: bold;

    a {
        color: #fff;
        font-size: 17px; 
    }

    a:hover {
        color: #fff;
    }

    .call-whatsapp {
        width: 50%;
        float: left;
        background-color: #01b101;
        height: 60px;
        padding-top: 18px;
    }

    .call-phone {
        width: 50%;
        float: left;
        background-color: #000;
        height: 60px;
        padding-top: 18px;
    }
}

footer {
    width: 100%;
    position: relative !important;
    z-index: 1;
    color: #757575;
    bottom: 0;
    position: fixed;
    background-color: #f8f8f8;
    padding: 20px 0 0px 0;
    border-top: solid 1px #7cb4d2;
    -webkit-box-shadow: 0 0 1px 0 rgba(0,0,0,0.5);
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.5);
    margin-top:20px;
    a {
        &:hover {
            text-decoration: none;
        }

        &:active {
            text-decoration: none;
        }
    }

    .footer-nav {
        margin-top: 10px;

        .title {
            color: #73a9bc;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 10px;
            margin: 0px 0px 15px 0 !important;
        }


        .mobile-none {
            display: none;
        }

        ul {
            margin: 0px;
            padding: 0;

            li {
                list-style: none;
                color: #808380;
                font-size: 15px;
                margin-bottom: 5px;

                a {
                    color: #808380;
                    font-size: 13px;

                    &:hover {
                        text-decoration: none;
                        color: #73a9bc;
                    }
                }
            }
        }


        .phone {
            margin: 10px 0 20px 0;
            position: relative;

            &:before {
                background: url(../images/footer/telefon-footer.png) no-repeat;
                width: 27px;
                height: 27px;
                content: "";
                display: inline-block;
                position: absolute;
                left: 0px;
                top: -2px;
            }

            .phone-title {
                display: none;
            }

            a {
                font-size: 17px;
                font-weight: bold;
                color: #71a8bc;
                margin-left: 33px;
                display: block;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .about-mobile {
            display: none;
        }

        .contact-mobile {
            display: none;
        }

        .social-media {
            .social-media-title {
                display: none;
            }

            ul {
                li {
                    display: inline-block;

                    img {
                        padding: 0 7px 0 0;
                        cursor: pointer;
                        max-width: 30px;
                    }
                }
            }
        }

        .logo {
            a {
                img {
                    max-width: 130px;
                    margin-bottom: 25px;
                }
            }
        }

        .legal-list {
            .tursab {
                margin-top: 12px;

                img {
                    width: 55px;

                    &.iata {
                        width: 40px;
                        margin-top: 0;
                        margin-left: 15px;
                    }
                }
            }
        }


        .copyright {
            font-size: 14px;
            border-top: 1px solid #7cb4d2;
            line-height: 50px;
            margin-top: 20px;

            a {
                color: #71a8bc;
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
            }
        }
    }
}



@media screen and (max-width: 1024px) {
    footer {
        padding: 30px 0;

        .footer-nav {
            ul {
                li {
                    font-size: 12px;
                }
            }

            .address {
                font-size: 12px;
            }

            .social-media {
                ul {
                    li {
                        img {
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .legal-list {
                ul {
                    li {
                        font-size: 10px;
                        margin-bottom: 9px;

                        &.tursab {
                            margin-top: 13px;

                            img {
                                width: 45px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px){

    footer-fixed
    {
        display:block;
        position:fixed;
        bottom:0px;
        left:0px;  
        z-index:9999;
    }

    .flight-footer {
        .item:nth-child(3) {
            clear: both;
        }
        .item{
            margin-bottom:30px; 
        }
    }

    footer {
        .footer-nav {
            > .container {
                .col-xs-12{
                    .col-sm-3:nth-child(3n+3){
                        clear:both; 
                    }
                    .col-sm-3{
                        margin-bottom:30px;  
                    }
                }
            }

            .phone { 
                a {
                    font-size:15px;  
                }
            }

            .social-media {
                ul {
                    li {
                        img {
                            padding: 2px;
                        }
                    }
                }
            }

            .title {
                font-size: 14px;
            }

            .logo {
                img {
                }
            }

            .legal-list {
                .tursab {
                    img {
                        &.iata {
                            margin-top: 0;
                            margin-left: 1px;
                        }
                    }
                }
            }

            .copyright {
                font-size: 12px;
                margin-top: 0px;
            }
        }
    }
}

@media screen and (max-width: 640px){
    footer {
        .footer-nav {


            .about-title {
                margin-top: 10px;
            }

            .contact-title {
                margin-top: 10px;
            }

            ul {
                li {
                    font-size: 15px;
                }
            }

            .phone {
                text-align: left;
                margin: 0;
                padding: 15px 0;

                &:before {
                    display: none;
                }

                &:after {
                    position: absolute;
                    content: "";
                    width: 95%;
                    height: 1px;
                    border-bottom: 1px solid #c0c0c0;
                    right: 0;
                    bottom: 0px; 
                } 

                .phone-title {
                    padding: 10px 0;
                    display: block;
                    color: #225979;
                    font-size: 24px;
                    font-weight: 500;
                    text-align: center;
                }

                a {

                    &:before {
                        background: url(../images/footer/telefon-footer.png) no-repeat;
                        width: 27px;
                        height: 27px;
                        content: "";
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        top: 14px;
                    }
                }
            }

            .about {
                display: none;
            }

            .contact {
                display: none;
            }

            .about-mobile {
                display: block;
            }

            .contact-mobile {
                display: block;
            }

            .social-media {


                .social-media-title {
                    padding: 10px 0;
                    padding: 20px 0;
                    display: block;
                    color: #225979;
                    font-size: 24px;
                    font-weight: 500;
                    text-align: center;
                }

                ul {
                    display: inline-block;

                    li {
                        img {
                            width: 50px;
                            margin: 0;
                            padding-right: 6px;
                        }
                    }
                }
            }

            .logo {
                text-align: center;
                background: #ebebeb;
                padding: 15px 0;

                img {
                    width: 116px;
                }
            }

            .legal-list { 
                .tursab {
                    img {
                        &.iata {
                            margin-left: 15px;
                        }
                    }
                }
            }

            .copyright {
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    footer { 
        .footer-nav {
            .title {
                font-size: 14px;
                margin-top: 10px;
            }

            .phone {

                &:before {
                    left: 5px;
                }
            } 

            .logo {
                margin-top: 15px;

                img {
                    width: 115px;
                }
            }

            .legal-list {
                ul {
                    li {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 320px){
    footer {
        .footer-nav {
            .title{
                margin: 0;
            }
            ul {
                li{
                    font-size: 12px;
                }
            }
        }
    }
}